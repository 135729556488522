@import 'styles/mixins.scss';

.mid-wrapper {
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: row;

    gap: 1rem;

    @include special-sections {
        flex-direction: column;
        align-items: center;
    }

    .mid-one {
        max-width: 500px;
        flex-shrink: 0;
        background-color: #ffffff;
        box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
        border-radius: 1rem;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        margin: auto;

        .one-top {
            display: flex;
            justify-content: space-between;

            @include small-phone-only {
                flex-direction: column;
                gap: 2rem;
            }

            .image-containerUser {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.7rem;
                width: 250px;

                .profile-avatar {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    border: 3px solid lightgrey;
                    box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                        object-position: center;
                    }
                }
            }

            .info-detailsUsers {
                width: 250px;
                display: flex;
                flex-direction: column;
                gap: 0.75rem;

                @include small-phone-only {
                    align-items: center;
                }

                .one-left-brands {
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        .one-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 0.75rem;
            margin: 0 auto;
            max-width: 350px;

            @include small-phone-only {
                margin-top: 1rem;
                flex-direction: column;
            }

            &-separator {
                color: #c4c4c4;
                width: 1px;
                height: 40px;

                @include small-phone-only {
                    width: 120px;
                    height: 1px;
                }
            }
        }
    }

    .mid-two {
        width: calc(100% - 500px);
        flex-shrink: 0;

        @include special-sections {
            width: 100%;
        }
        .bottom-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
        }
    }
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .form-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 1rem;

        @include tablet-portrait {
            flex-direction: column;
        }

        .form-column {
            width: calc(50% - 8px);
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;

            @include tablet-portrait {
                width: 100%;
            }

            .column-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }
    }

    .image-wrapper {
        display: flex;
        flex-direction: column;

        .image-wrapperContainer {
            padding-top: 1rem;
            display: flex;
            align-items: center;
            gap: 2rem;
        }
    }
}

.add-user-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .image-wrapper {
        display: flex;
        flex-direction: column;

        .imageContainer {
            padding-top: 1rem;
            display: flex;
            align-items: center;
            gap: 2rem;
        }
    }

    .brands-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .checkboxGroupWrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .checkboxGroup {
                display: flex;
                gap: 16px;

                label {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(0, 0, 0, 0.62);

                    input[type='checkbox'] {
                        /* Add if not using autoprefixer */
                        -webkit-appearance: none;
                        appearance: none;
                        /* For iOS < 15 to remove gradient background */
                        background-color: #fff;
                        /* Not removed via appearance */
                        margin: 0;
                    }

                    input[type='checkbox'] {
                        appearance: none;
                        background-color: #fff;
                        margin: 0;
                        font: inherit;
                        color: red;
                        width: 16px;
                        height: 16px;
                        border: 2px solid #e9e9e9;
                        border-radius: 3px;
                        transform: translateY(-0.075em);
                        display: grid;
                        place-content: center;
                    }

                    input[type='checkbox']:disabled {
                        background-color: #f0f0f0;
                    }

                    input[type='checkbox']::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        transform: scale(0);
                        border-radius: 2px;
                        transition: 120ms transform ease-in-out;
                        box-shadow: inset 10px 10px #5b80ba;
                    }

                    input[type='checkbox']:checked::before {
                        transform: scale(1);
                    }
                }
            }

            .checkboxGroupError {
                color: #ff4b55;
                font-family: Montserrat, sans-serif;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.66;
            }
        }
    }

    .notWorkpoint {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 0.6rem;
    }
}

.move-clients-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}
